.App {
  text-align: left;
  max-width: 600px;
  margin: 0px auto auto;
}

.section {
  text-align: left;
  max-width: 500px;
  margin: 0px auto auto;
}

.account {
  text-align: right;
  height: 50px;
  max-width: 500px;
  margin: 20px auto 4px;
}

.accountLink {
  margin-top: 4px;
  float: left;
}

.gallery {
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}